<template>
    <div>
        <v-alert v-if="sent" outlined type="success" text>
            <div class="text-h5">Solicitação enviada com sucesso.</div>
            Verfique seu email, você vai receber um link para prosseguirmos para a próxima etapa.
        </v-alert>
        <div v-else>
            <v-toolbar dark color="primary">
                <v-toolbar-title class="text-h6">{{ $t("Faça parte da nossa time!") }}</v-toolbar-title>
            </v-toolbar>

            <v-card color="primary">
                <v-card-text primary-title class="white--text">
                    {{ $t("Caso você tenha interesse em fazer parte da nossa rede de especialistas, preencha o formulário abaixo e verifique seu e-mail.") }}
                </v-card-text>
            </v-card>
            <v-container class="text-center">
                <v-form ref="form" v-model="valid" @submit.prevent="validate">

                    <v-text-field v-model="nameCapitalize" :rules="[rules.required, rules.min(5)]" :label="$t('Como você quer ser chamado')" required />
                    <v-text-field type="email" v-model="email" :rules="[rules.required, rules.email]" :label="$t('E-mail')" required />
                    <v-text-field type="tel" v-model="item.phone" :rules="[rules.cellphone]" :label="$t('Celular')" v-mask="['(##) ####-####', '(##) #####-####']" required />

                    <v-btn class="ma-2" color="success" @click="validate" :disabled="!valid" :loading="saving">{{ $t("Enviar solicitação") }}</v-btn>

                    <router-link :to="{ path: '/' }" tag="v-btn">
                        <v-btn class="ma-2" color="error">{{ $t("Voltar") }} </v-btn>
                    </router-link>
                </v-form>
            </v-container>
        </div>
    </div>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },
    name: "Register",
    data() {
        return {
            rules,
            saving: false,
            valid: true,
            sent: false,
            item: {
                name: "",
                email: "",
                phone: null,
            },
        };
    },
    computed: {
        email: {
            get: function () {
                return this.item.email;
            },
            set: function (data) {
                this.item.email = data.toLowerCase();
            },
        },
        nameCapitalize: {
            get: function () {
                return this.item.name;
            },
            set: function (data) {
                this.item.name = this.rules.filters.capitalized(data);
            },
        },
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.item.redirect = this.$store.state.routeBeforeRegister;
                this.saving = true;
                this.$http
                    .post("expert-register", this.item)
                    .then(() => {
                        this.sent = true;
                    })
                    .catch((error) => {
                        this.saving = false;
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Não foi possível enviar sua solicitação."));
                    });
            }
        },
    },
};
</script>
